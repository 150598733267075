import React, { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';

const MediaLibrary = lazy(() => import('app/modules/mediaLibrary/modules'));
const Albums = lazy(() => import('app/modules/mediaLibrary/modules/albums'));
const AlbumDetail = lazy(
  () => import('app/modules/mediaLibrary/modules/albums/modules/detail'),
);

export const mediaLibraryRouter: RouteObject = {
  path: routeConfig.mediaLibrary.path,
  element: (
    <Suspense fallback={<Skeleton width="100%" height="100%" />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <MediaLibrary />,
    },
    {
      path: routeConfig.mediaLibrary.routes.albums.path,
      element: <Albums />,
    },
    {
      path: routeConfig.mediaLibrary.routes.album.path,
      element: <AlbumDetail />,
    },
  ],
};
