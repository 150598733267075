import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { colors, Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import { MediaLibraryApi } from 'app/modules/mediaLibrary/api';
import i18n from 'app/utils/i18n';
import { UserPermission } from 'constants/userPermission';
import Logger from 'utils/logger';
import Popup from 'utils/popup';

export const deleteAsset = (context: ActionContext) =>
  createAction(
    {
      id: 'delete-asset',
      label: i18n.t('delete'),
      icon: <Icon icon={faTrash} />,
      scopes: UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA,
      //TODO:
      perform: async ({ id }: { id: string }) => {
        const { isConfirmed } = await Popup.confirm({
          title: 'Are you sure you want to delete this asset?',
          text: 'This action cannot be undone.',
          showCancelButton: true,
          confirmButtonColor: colors.danger.default,
          confirmButtonText: 'Delete',
          width: '400px',
        });

        if (isConfirmed) {
          try {
            Logger.log('Confirmed:');

            await MediaLibraryApi.deleteAsset(id.toString());
          } catch (error) {
            Logger.log('Error deleting asset:', error);
          }
        }
      },
    },
    context,
  );
