import { get, httpDelete, patch, post, put } from 'api/client';
import env from 'config';
import {
  AssetsParams,
  FinalizeUploadResponseData,
  MediaAlbum,
  MediaAlbumDetail,
  MediaAlbumParams,
  MediaAlbumsParams,
  MediaAsset,
  RegisterUploadResponseData,
  UploadUrl,
} from '../types';
import Logger from 'utils/logger';
import { getExtendedTimeout } from 'utils/request';

export const MediaLibraryApi = {
  getAllAlbums(
    params?: MediaAlbumsParams & {
      sort_by?: string;
      limit?: number;
      sort_direction?: 'asc' | 'desc';
      page?: number;
      assigned_model_type?: string;
      assigned_model_name?: string;
      assigned_model_id?: string;
    },
  ): Promise<ApiResponse<MediaAlbum[]>> {
    return get(`/media-library/albums`, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },

  getAlbum(id: string): Promise<MediaAlbumDetail> {
    return get(`/media-library/albums/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  createAlbum({
    name,
    description,
    parent_id,
    assigned_model_id,
    assigned_model_name,
    assigned_model_type,
  }: MediaAlbumParams): Promise<MediaAlbum> {
    const data = new FormData();
    data.append('name', name);
    if (description) {
      data.append('description', description);
    }
    if (assigned_model_id && assigned_model_name && assigned_model_type) {
      data.append('assigned_model_id', assigned_model_id);
      data.append('assigned_model_name', assigned_model_name);
      data.append('assigned_model_type', assigned_model_type);
    }

    if (parent_id) {
      data.append('parent_id', parent_id);
    }

    return post(`/media-library/albums`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  duplicateAlbum({
    id,
    data: { name, assigned_model_id, assigned_model_name, assigned_model_type },
  }: {
    id: string;
    data: MediaAlbumParams;
  }): Promise<MediaAlbum> {
    const data = new FormData();
    data.append('name', name);

    if (assigned_model_id && assigned_model_name && assigned_model_type) {
      data.append('assigned_model_id', assigned_model_id);
      data.append('assigned_model_name', assigned_model_name);
      data.append('assigned_model_type', assigned_model_type);
    }

    return post(`/media-library/albums/${id}/duplicate`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  updateAlbum({
    id,
    data: {
      name,
      description,
      assigned_model_id,
      assigned_model_name,
      assigned_model_type,
    },
  }: {
    id: string;
    data: MediaAlbumParams;
  }): Promise<ApiResponse<MediaAlbum>> {
    const data = {
      name,
      description,
      assigned_model_id,
      assigned_model_name,
      assigned_model_type,
    };
    return patch(`/media-library/albums/${id}`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  deleteAlbum: (
    id: string,
  ): Promise<{
    data: [];
    status: true;
    errors: null;
  }> => {
    return httpDelete(`/media-library/albums/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  setAsMasterAlbum(id: string): Promise<{
    data: MediaAlbum;
    status: true;
    errors: null;
  }> {
    return post(`/media-library/albums/${id}/set-as-master-album`, null, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  getAssets(
    params?: AssetsParams & {
      sort_by?: string;
      limit?: number;
      sort_direction?: 'asc' | 'desc';
      page?: number;
      assigned_model_type?: string;
      assigned_model_name?: string;
      assigned_model_id?: string;
    },
  ): Promise<ApiResponse<MediaAsset[]>> {
    return get(`/media-library/assets`, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },

  deleteAsset: (
    id: string,
  ): Promise<{
    data: [];
    status: true;
    errors: null;
  }> => {
    return httpDelete(`/media-library/assets/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  async upload(
    inputData: {
      albumId: string;
      file: File;
    },
    config?: {
      onUploadProgress?: (progress: number) => void;
    },
  ) {
    const { albumId, file } = inputData;

    function registerUpload() {
      const formData = new FormData();

      formData.append('album_id', albumId);
      formData.append('files[*][name]', file.name);
      formData.append('files[*][mime_type]', file.type);
      formData.append('files[*][size]', file.size.toString());

      return post<ApiResponse<RegisterUploadResponseData[]>>(
        '/media-library/assets/register-upload',
        formData,
        {
          baseURL: env.API_SERVICE_ENDPOINT,
        },
      );
    }

    async function doUpload(uploadUrls: UploadUrl[], file: File) {
      const parts: {
        etag: string;
        part: number;
      }[] = [];
      let fileOffset = 0;

      for (const { url, part, file_size } of uploadUrls) {
        const fileChunk = file.slice(fileOffset, fileOffset + file_size);

        fileOffset += file_size;
        const percentagesByChunk = 100 / uploadUrls.length;

        const uploadResponse = await put<{ etag: string }>(url, fileChunk, {
          headers: {
            'Content-Type': file.type,
          },
          timeout: getExtendedTimeout(10),
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable && !!config?.onUploadProgress) {
              const progress =
                (progressEvent.loaded / progressEvent.total) *
                  percentagesByChunk +
                (part - 1) * percentagesByChunk;

              config?.onUploadProgress?.(Math.ceil(progress));
            }
          },
        });

        if (!uploadResponse.etag) {
          Logger.error('Etag not found in response', uploadResponse);
          throw new Error('Upload failed');
        }

        config?.onUploadProgress?.(Math.ceil(percentagesByChunk * part));

        parts.push({
          etag: uploadResponse.etag,
          part,
        });
      }

      return parts;
    }

    async function finalizeUpload(uploadData: {
      id: string;
      type: string;
      parts: { etag: string; part: number }[];
      assetId: string;
    }) {
      const formData = new FormData();

      formData.append('album_id', albumId);
      formData.append('upload_type', uploadData.type);
      formData.append('upload_id', uploadData.id);

      uploadData.parts.forEach((part, index) => {
        formData.append(`parts[${index}][part]`, part.part.toString());
        formData.append(`parts[${index}][etag]`, part.etag);
      });

      return post<ApiResponse<FinalizeUploadResponseData>>(
        `/media-library/assets/${uploadData.assetId}/finalize-upload`,
        formData,
        {
          baseURL: env.API_SERVICE_ENDPOINT,
        },
      );
    }

    const { data } = await registerUpload();
    const { upload_urls, upload_id, upload_type, asset_id } = data[0];

    if (!upload_urls) {
      Logger.error('Upload urls not found', data[0]);
      throw new Error('Upload failed');
    }

    const parts = await doUpload(upload_urls, file);

    return finalizeUpload({
      id: upload_id,
      type: upload_type,
      parts,
      assetId: asset_id,
    });
  },
};
