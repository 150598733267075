import { AxiosResponse } from 'axios';
import { Responses } from 'constants/responses';
import { AppThunks } from 'modules/app/appActions';
import store from 'store';
import { Dispatch } from 'types/Store';
import dayjs from 'dayjs';
import { BrowserStorage } from 'utils/browserStorage';
import { ApiClientError, ApiClientErrorParams } from 'api/client';
import { getUserMessage } from 'utils/request';
import config from 'config';

const isUnpaidAccess = (status: number) => {
  return status === Responses.ERROR.PAYMENT_REQUIRED;
};

const isUnauthorizedAccess = (status: number) => {
  return status === Responses.ERROR.UNAUTHORIZED;
};

const isNotJSONRespnse = (headers: any) => {
  return headers['content-type']?.indexOf('application/json');
};

const isNotStatusTrue = (response: AxiosResponse) => {
  if (isS3Response(response)) {
    return false;
  }

  return 'status' in response.data && response.data.status === false;
};

const isNotSuccessStatusCode = (status: any) => {
  return !Object.values(Responses.SUCCESSFUL).includes(status);
};

const rejectUnauthorizeAccess = (response: AxiosResponse) => {
  store.dispatch<Dispatch>(AppThunks.logoutUser());

  return rejectResponse({ message: 'Unauthorized access', response });
};

const rejectNotJSONResponse = (response: AxiosResponse) => {
  return rejectResponse({
    message: 'Server not responded with JSON response',
    response,
  });
};

const rejectNotStatusTrue = (response: AxiosResponse) => {
  return rejectResponse({
    message: 'Server not responded with status true',
    response,
  });
};

const rejectNotSuccessStatusCode = (response: AxiosResponse) => {
  return rejectResponse({
    message: 'Server not responded with success like status code',
    response,
  });
};

const rejectUnpaidAccess = () => {
  const reloadedDate = BrowserStorage.get(
    BrowserStorage.keys.ExpiredReloadDate,
  );
  const today = dayjs().format('YYYY-MM-DD');

  if (reloadedDate !== today) {
    BrowserStorage.set(BrowserStorage.keys.ExpiredReloadDate, today);
    return (window.location.href = '/');
  }
};

const rejectResponse = (params: ApiClientErrorParams) => {
  return Promise.reject(
    new ApiClientError({
      userMessage: getUserMessage(params.response),
      ...params,
    }),
  );
};

export const shouldRejectWrongResponse = (response: AxiosResponse) => {
  return (
    isUnpaidAccess(response.status) ||
    isUnauthorizedAccess(response.status) ||
    isNotJSONRespnse(response.headers) ||
    isNotStatusTrue(response) ||
    isNotSuccessStatusCode(response.status)
  );
};

export const rejectWrongResponse = (response: AxiosResponse) => {
  if (isUnpaidAccess(response.status)) {
    return rejectUnpaidAccess();
  }

  if (isUnauthorizedAccess(response.status)) {
    return rejectUnauthorizeAccess(response);
  }

  if (isNotJSONRespnse(response.headers)) {
    return rejectNotJSONResponse(response);
  }

  if (isNotStatusTrue(response)) {
    return rejectNotStatusTrue(response);
  }

  if (isNotSuccessStatusCode(response.status)) {
    return rejectNotSuccessStatusCode(response);
  }
};

export const formatAwsUploadResponse = (response: AxiosResponse) => {
  return {
    etag: response.headers.etag.replace(/"/g, ''),
  };
};

export const isS3Response = (response: AxiosResponse) => {
  return response.config.url?.includes(config.AWS_UPLOAD_BASE_URL);
};
