import React, { FC } from 'react';

type Props = {
  onClick?: () => void;
};
export const MediaPreviewOverlay: FC<Props> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-rounded-md tw-bg-grayscale-180 tw-opacity-0 tw-transition-all group-hover:tw-opacity-25"
    />
  );
};
